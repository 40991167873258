/* BASICS */
.CodeMirror {
  /* Set height, width, borders, and global font properties here */
  font-family: monospace;
  height: 300px;
  color: black;
  direction: ltr; }

/* PADDING */
.CodeMirror-lines {
  padding: 4px 0;
  /* Vertical padding around content */ }

.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  padding: 0 4px;
  /* Horizontal padding of content */ }

.CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  background-color: white;
  /* The little square between H and V scrollbars */ }

/* GUTTER */
.CodeMirror-gutters {
  border-right: 1px solid #ddd;
  background-color: #f7f7f7;
  white-space: nowrap; }

.CodeMirror-linenumber {
  padding: 0 3px 0 5px;
  min-width: 20px;
  text-align: right;
  color: #999;
  white-space: nowrap; }

.CodeMirror-guttermarker {
  color: black; }

.CodeMirror-guttermarker-subtle {
  color: #999; }

/* CURSOR */
.CodeMirror-cursor {
  border-left: 1px solid black;
  border-right: none;
  width: 0; }

/* Shown when moving in bi-directional text */
.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver; }

.cm-fat-cursor .CodeMirror-cursor {
  width: auto;
  border: 0 !important;
  background: #7e7; }

.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1; }

.cm-fat-cursor .CodeMirror-line::selection,
.cm-fat-cursor .CodeMirror-line > span::selection,
.cm-fat-cursor .CodeMirror-line > span > span::selection {
  background: transparent; }

.cm-fat-cursor .CodeMirror-line::-moz-selection,
.cm-fat-cursor .CodeMirror-line > span::-moz-selection,
.cm-fat-cursor .CodeMirror-line > span > span::-moz-selection {
  background: transparent; }

.cm-fat-cursor {
  caret-color: transparent; }

@keyframes blink {
  0% { }
  50% {
    background-color: transparent; }
  100% { } }

/* Can style cursor different in overwrite (non-insert) mode */
.cm-tab {
  display: inline-block;
  text-decoration: inherit; }

.CodeMirror-rulers {
  position: absolute;
  left: 0;
  right: 0;
  top: -50px;
  bottom: 0;
  overflow: hidden; }

.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  top: 0;
  bottom: 0;
  position: absolute; }

/* DEFAULT THEME */
.cm-s-default .cm-header {
  color: blue; }

.cm-s-default .cm-quote {
  color: #090; }

.cm-negative {
  color: #d44; }

.cm-positive {
  color: #292; }

.cm-header, .cm-strong {
  font-weight: bold; }

.cm-em {
  font-style: italic; }

.cm-link {
  text-decoration: underline; }

.cm-strikethrough {
  text-decoration: line-through; }

.cm-s-default .cm-keyword {
  color: #708; }

.cm-s-default .cm-atom {
  color: #219; }

.cm-s-default .cm-number {
  color: #164; }

.cm-s-default .cm-def {
  color: #00f; }

.cm-s-default .cm-variable-2 {
  color: #05a; }

.cm-s-default .cm-variable-3, .cm-s-default .cm-type {
  color: #085; }

.cm-s-default .cm-comment {
  color: #a50; }

.cm-s-default .cm-string {
  color: #a11; }

.cm-s-default .cm-string-2 {
  color: #f50; }

.cm-s-default .cm-meta {
  color: #555; }

.cm-s-default .cm-qualifier {
  color: #555; }

.cm-s-default .cm-builtin {
  color: #30a; }

.cm-s-default .cm-bracket {
  color: #997; }

.cm-s-default .cm-tag {
  color: #170; }

.cm-s-default .cm-attribute {
  color: #00c; }

.cm-s-default .cm-hr {
  color: #999; }

.cm-s-default .cm-link {
  color: #00c; }

.cm-s-default .cm-error {
  color: #f00; }

.cm-invalidchar {
  color: #f00; }

.CodeMirror-composing {
  border-bottom: 2px solid; }

/* Default styles for common addons */
div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0b0; }

div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #a22; }

.CodeMirror-matchingtag {
  background: rgba(255, 150, 0, 0.3); }

.CodeMirror-activeline-background {
  background: #e8f2ff; }

/* STOP */
/* The rest of this file contains styles related to the mechanics of
   the editor. You probably shouldn't touch them. */
.CodeMirror {
  position: relative;
  overflow: hidden;
  background: white; }

.CodeMirror-scroll {
  overflow: scroll !important;
  /* Things will break if this is overridden */
  /* 50px is the magic margin used to hide the element's real scrollbars */
  /* See overflow: hidden in .CodeMirror */
  margin-bottom: -50px;
  margin-right: -50px;
  padding-bottom: 50px;
  height: 100%;
  outline: none;
  /* Prevent dragging from highlighting the element */
  position: relative;
  z-index: 0; }

.CodeMirror-sizer {
  position: relative;
  border-right: 50px solid transparent; }

/* The fake, visible scrollbars. Used to force redraw during scrolling
   before actual scrolling happens, thus preventing shaking and
   flickering artifacts. */
.CodeMirror-vscrollbar, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  position: absolute;
  z-index: 6;
  display: none;
  outline: none; }

.CodeMirror-vscrollbar {
  right: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: scroll; }

.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: scroll; }

.CodeMirror-scrollbar-filler {
  right: 0;
  bottom: 0; }

.CodeMirror-gutter-filler {
  left: 0;
  bottom: 0; }

.CodeMirror-gutters {
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100%;
  z-index: 3; }

.CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: -50px; }

.CodeMirror-gutter-wrapper {
  position: absolute;
  z-index: 4;
  background: none !important;
  border: none !important; }

.CodeMirror-gutter-background {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 4; }

.CodeMirror-gutter-elt {
  position: absolute;
  cursor: default;
  z-index: 4; }

.CodeMirror-gutter-wrapper ::selection {
  background-color: transparent; }

.CodeMirror-gutter-wrapper ::-moz-selection {
  background-color: transparent; }

.CodeMirror-lines {
  cursor: text;
  min-height: 1px;
  /* prevents collapsing before first draw */ }

.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  /* Reset some styles that the rest of the page might have set */
  border-radius: 0;
  border-width: 0;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  position: relative;
  overflow: visible;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-variant-ligatures: contextual;
  font-feature-settings: "calt";
  font-variant-ligatures: contextual; }

.CodeMirror-wrap pre.CodeMirror-line,
.CodeMirror-wrap pre.CodeMirror-line-like {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal; }

.CodeMirror-linebackground {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0; }

.CodeMirror-linewidget {
  position: relative;
  z-index: 2;
  padding: 0.1px;
  /* Force widget margins to stay inside of the container */ }

.CodeMirror-rtl pre {
  direction: rtl; }

.CodeMirror-code {
  outline: none; }

/* Force content-box sizing for the elements where we expect it */
.CodeMirror-scroll,
.CodeMirror-sizer,
.CodeMirror-gutter,
.CodeMirror-gutters,
.CodeMirror-linenumber {
  box-sizing: content-box; }

.CodeMirror-measure {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  visibility: hidden; }

.CodeMirror-cursor {
  position: absolute;
  pointer-events: none; }

.CodeMirror-measure pre {
  position: static; }

div.CodeMirror-cursors {
  visibility: hidden;
  position: relative;
  z-index: 3; }

div.CodeMirror-dragcursors {
  visibility: visible; }

.CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible; }

.CodeMirror-selected {
  background: #d9d9d9; }

.CodeMirror-focused .CodeMirror-selected {
  background: #d7d4f0; }

.CodeMirror-crosshair {
  cursor: crosshair; }

.CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection {
  background: #d7d4f0; }

.CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection {
  background: #d7d4f0; }

.cm-searching {
  background-color: #ffa;
  background-color: rgba(255, 255, 0, 0.4); }

/* Used to force a border model for a node */
.cm-force-border {
  padding-right: .1px; }

@media print {
  /* Hide the cursor when printing */
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden; } }

/* See issue #2901 */
.cm-tab-wrap-hack:after {
  content: ''; }

/* Help users use markselection to safely style text background */
span.CodeMirror-selectedtext {
  background: none; }

/* COLORS */
/* BREAKPOINTS */
/* SIZES */
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-family: 'Century Gothic', CenturyGothic, AppleGothic, sans-serif;
  color: #555;
  overflow: hidden; }

@media (max-width: 800px) {
  body {
    overflow: auto; } }

/* FLEX */
.flex {
  display: flex; }
  .flex-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .flex-row-normal {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap; }
      .flex-row-normal-nowrap {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap; }
    .flex-row-nowrap {
      width: 100%;
      display: flex;
      flex-wrap: nowrap; }
      @media (max-width: 400px) {
        .flex-row-nowrap.responsive-mobile {
          flex-wrap: wrap; } }
  .flex-col {
    display: flex;
    flex-direction: column; }
  .flex-centered {
    justify-content: center;
    align-items: center; }
  .flex-center {
    align-items: center; }
  .flex-center-horizontal {
    justify-content: center; }
  .flex-top {
    align-items: flex-start; }
  .flex-right {
    justify-content: flex-end; }
  .flex-bottom {
    align-items: flex-end; }
  .flex-spaced {
    justify-content: space-around;
    align-items: space-around;
    align-content: space-around; }
  .flex-space-between {
    justify-content: space-between; }

/* GAP */
.gap {
  grid-gap: 2rem;
  gap: 2rem; }
  .gap-none {
    grid-gap: 0;
    gap: 0; }
  .gap-mini {
    grid-gap: 0.25rem;
    gap: 0.25rem; }
  .gap-very-small {
    grid-gap: 0.5rem;
    gap: 0.5rem; }
  .gap-small {
    grid-gap: 1rem;
    gap: 1rem; }

/* WIDTH */
.width-full {
  width: 100%; }

.width-max-48 {
  max-width: 48%; }

.width-min-px100 {
  min-width: 100px; }

.width-min-px200 {
  min-width: 200px; }

/* HEIGHT */
.height-full {
  height: 100%; }

/* MARGIN */
.margin {
  margin: 2em; }
  .margin-top {
    margin-top: 2em; }
    .margin-top-small {
      margin-top: 1em; }
    .margin-top-very-small {
      margin-top: 0.5em; }
    .margin-top-mini {
      margin-top: 0.25em; }
    .margin-top-px1 {
      margin-top: 1px; }
  .margin-bottom {
    margin-bottom: 2em; }
    .margin-bottom-small {
      margin-bottom: 1em; }
    .margin-bottom-very-small {
      margin-bottom: 0.5em; }
    .margin-bottom-mini {
      margin-bottom: 0.25em; }
    .margin-bottom-none {
      margin-bottom: 0px; }
    .margin-bottom-px1 {
      margin-bottom: 1px; }
  .margin-left {
    margin-left: 2em; }
    .margin-left-small {
      margin-left: 1em; }
    .margin-left-very-small {
      margin-left: 0.5em; }
    .margin-left-mini {
      margin-left: 0.25em; }
  .margin-right {
    margin-right: 2em; }
    .margin-right-small {
      margin-right: 1em; }
    .margin-right-very-small {
      margin-right: 0.5em; }
    .margin-right-mini {
      margin-right: 0.25em; }
  .margin-horizontal {
    margin: 0 2em; }
    .margin-horizontal-small {
      margin: 0 1em; }
  .margin-vertical {
    margin: 2em 0; }
    .margin-vertical-small {
      margin: 1em 0; }
  .margin-none {
    margin: 0px; }

/* PADDING */
.padding {
  padding: 2em; }
  .padding-small {
    padding: 1em; }
  .padding-very-small {
    padding: 0.5em; }
  .padding-top {
    padding-top: 2em; }
    .padding-top-small {
      padding-top: 1em; }
    .padding-top-very-small {
      padding-top: 0.5em; }
  .padding-bottom {
    padding-bottom: 2em; }
    .padding-bottom-small {
      padding-bottom: 1em; }
    .padding-bottom-very-small {
      padding-bottom: 0.5em; }
    .padding-bottom-none {
      padding-bottom: 0px; }
  .padding-horizontal-small {
    padding-left: 1em;
    padding-right: 1em; }
  .padding-horizontal-very-small {
    padding-left: 0.5em;
    padding-right: 0.5em; }
  .padding-none {
    padding: 0px; }

/* TEXT */
.text-right {
  text-align: right; }

.text-bold {
  font-weight: bold; }

/* DIVIDER */
.divider-top {
  border-top: 1px solid #e8e8e8; }

.divider-bottom {
  border-bottom: 1px solid #e8e8e8; }

/* ICON */
.icon {
  cursor: pointer;
  color: #555; }
  .icon:hover {
    color: #000; }

/* COMPONENTS */
select {
  cursor: pointer; }

.checkbox label {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.65); }

/* ERROR */
.invalid {
  color: #ea5d5d; }

/* SUCCESS */
.success {
  color: #4caf50; }

/* COLORS */
.color-green {
  color: #2ECC40; }

.color-orange {
  color: #FF851B; }

.color-red {
  color: #FF4136; }

.color-white {
  color: #fff; }

/* ANT */
.ant-input-disabled {
  color: rgba(0, 0, 0, 0.65); }

.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.65); }

.ant-input.read-only, .ant-input-number.read-only {
  background-color: #f5f5f5; }
  .ant-input.read-only:hover, .ant-input.read-only:focus, .ant-input-number.read-only:hover, .ant-input-number.read-only:focus {
    border-color: #d9d9d9;
    box-shadow: none; }

.ant-select-disabled {
  color: rgba(0, 0, 0, 0.65); }

.ant-card-body {
  padding: 1.5em; }

.ant-card-head {
  padding: 0 1.5em; }
  .ant-card-head-title {
    padding: 1em 0; }

.ant-menu-inline .ant-menu-item {
  width: 100% !important; }

.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
  color: rgba(0, 0, 0, 0.65); }

/* VISIBILITY */
.hidden {
  display: none; }

/* OTHER */
.pre {
  white-space: pre;
  font-family: 'Century Gothic', CenturyGothic, AppleGothic, sans-serif; }

.table-checkbox {
  margin: 0; }

.link {
  cursor: pointer;
  text-decoration: underline; }
  .link:hover {
    text-decoration: none; }

.bold {
  font-weight: 700; }

/* Dialog headings */
.h1-dialog {
  font-size: 24px;
  color: #555555;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  font-weight: 700;
  margin-top: 1em;
  margin-bottom: 0.25em; }

.h2-dialog {
  font-size: 14px;
  color: #555555;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  font-weight: 700;
  margin-top: 1em;
  margin-bottom: 0.25em; }

.hr-dialog {
  width: 50%;
  margin-left: 0em;
  margin-top: 0px;
  margin-bottom: 10px; }

/* Overflow */
.auto-overflowX-visible-only-on-hover {
  overflow: hidden; }
  .auto-overflowX-visible-only-on-hover:hover {
    overflow-x: auto; }

.auto-overflowY-visible-only-on-hover {
  overflow: hidden; }
  .auto-overflowY-visible-only-on-hover:hover {
    overflow-y: auto; }

.app-header {
  padding: 0;
  /* IE */ }
  .app-header .ant-menu-item.disabled {
    cursor: auto; }
    .app-header .ant-menu-item.disabled:hover {
      color: rgba(255, 255, 255, 0.65); }
  .app-header .ant-menu-item-selected {
    background-color: #337ab7 !important; }
  .app-header.center.ie .content .title {
    position: absolute;
    left: 50vw;
    top: 32px;
    transform: translate(-50%, -50%); }
  .app-header .content {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .app-header .content .top,
    .app-header .content .bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%; }
    .app-header .content .top {
      padding: 0 1em;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      .app-header .content .top.with-menu-button {
        justify-content: space-between; }
      .app-header .content .top .right {
        height: 100%;
        line-height: 100%;
        display: flex;
        align-items: center; }
    .app-header .content .title {
      color: #fff;
      margin: 0;
      margin-right: 1em; }
      .app-header .content .title.title-hidden {
        display: none; }
    .app-header .content .user-button {
      max-width: 280px; }
    .app-header .content .menu-all {
      display: none;
      width: 100%; }
      .app-header .content .menu-all-button {
        display: none;
        max-width: 280px; }
      .app-header .content .menu-all li {
        margin: 0 !important;
        height: 45px !important;
        line-height: 45px !important; }
  .app-header.center .content {
    padding: 0 1em;
    flex-direction: row;
    justify-content: flex-end; }
    .app-header.center .content .title {
      position: absolute;
      left: 50vw;
      transform: translateX(-50%);
      margin-right: 0; }
  .app-header .item-language {
    display: none; }

@media (max-width: 800px) {
  .app-header.with-menu {
    height: auto; }
  .app-header .content .top.with-menu-button {
    justify-content: flex-end; }
  .app-header .content .top.with-menu {
    padding: 1em;
    height: auto; }
  .app-header .content .top .menu-button {
    display: none; }
  .app-header .content .top .title {
    display: block; }
    .app-header .content .top .title.title-hidden {
      display: block; }
  .app-header .content .menu,
  .app-header .content .dropdown-user {
    display: none; }
  .app-header .content .menu-all {
    display: block; }
    .app-header .content .menu-all-button {
      display: block; } }

@media (min-width: 400px) and (max-width: 800px) {
  .app-header.normal .content .top {
    justify-content: space-between; } }

@media (max-width: 500px) {
  .app-header.normal .content .top {
    justify-content: flex-end; }
    .app-header.normal .content .top .title {
      display: none; }
    .app-header.normal .content .top .right,
    .app-header.normal .content .top .menu-all-button {
      max-width: 100%; }
  .app-header.normal .button-language {
    display: none; }
  .app-header.normal.center .button-language {
    display: block; }
  .app-header.normal .item-language {
    display: block; } }

@media (max-width: 400px) {
  .app-header .content .top {
    justify-content: space-between; }
    .app-header .content .top.with-menu-button {
      justify-content: space-between; }
    .app-header .content .top .title {
      position: static;
      left: 0;
      transform: none; } }

@media (max-width: 300px) {
  .app-header .content {
    justify-content: flex-end; }
    .app-header .content .title {
      display: none; }
      .app-header .content .title.title-hidden {
        display: none; } }

@media (max-width: 200px) {
  .app-header .content .top {
    justify-content: flex-end; }
    .app-header .content .top .title {
      display: none; }
      .app-header .content .top .title.title-hidden {
        display: none; } }

.ant-breadcrumb span > span {
  font-size: 16px;
  color: #555; }
  .ant-breadcrumb span > span.link {
    cursor: pointer; }
    .ant-breadcrumb span > span.link:hover {
      text-decoration: underline; }

.button {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis; }

/*!
 * https://github.com/YouCanBookMe/react-datetime
 */
.rdt {
  position: relative; }

.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #f9f9f9; }

.rdtOpen .rdtPicker {
  display: block; }

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static; }

.rdtPicker .rdtTimeToggle {
  text-align: center; }

.rdtPicker table {
  width: 100%;
  margin: 0; }

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px; }

.rdtPicker td {
  cursor: pointer; }

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer; }

.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999; }

.rdtPicker td.rdtToday {
  position: relative; }

.rdtPicker td.rdtToday:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px; }

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff; }

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker td span.rdtOld {
  color: #999999; }

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker th {
  border-bottom: 1px solid #f9f9f9; }

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default; }

.rdtPicker th.rdtSwitch {
  width: 100px; }

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top; }

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  /* Konqueror */
  /* Firefox */
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker thead tr:first-child th {
  cursor: pointer; }

.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee; }

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9; }

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer; }

.rdtPicker button:hover {
  background-color: #eee; }

.rdtPicker thead button {
  width: 100%;
  height: 100%; }

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer; }

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee; }

.rdtCounters {
  display: inline-block; }

.rdtCounters > div {
  float: left; }

.rdtCounter {
  height: 100px; }

.rdtCounter {
  width: 40px; }

.rdtCounterSeparator {
  line-height: 100px; }

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  /* Konqueror */
  /* Firefox */
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtCounter .rdtBtn:hover {
  background: #eee; }

.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em; }

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px; }

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px; }

.rdtTime td {
  cursor: default; }

.datetimepicker-component input {
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s;
  font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .datetimepicker-component input:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important; }
  .datetimepicker-component input:focus {
    border-color: #40a9ff;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    border-right-width: 1px !important; }

.datetimepicker-component.alignRight .rdtPicker {
  right: 0; }

/* Dublin Core Descriptive Metadata Detail */
.descriptive-metadata-section {
  margin-top: 2.5rem; }
  .descriptive-metadata-section .dm-title-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    padding: 1rem; }
    .descriptive-metadata-section .dm-title-container:hover {
      background-color: #f7f7f7;
      cursor: pointer; }
    .descriptive-metadata-section .dm-title-container .dm-title {
      font-size: 24px;
      color: #555555;
      font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
      font-weight: 700; }
    .descriptive-metadata-section .dm-title-container .dm-icon {
      font-size: 24px; }

.dublin-core-metadata-group .title-bar {
  width: 100%;
  padding: 1rem;
  border-top: 3px solid #e8e8e8;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.dublin-core-metadata-group .title-bar:hover {
  background-color: #f7f7f7; }

.dublin-core-metadata-group .dc-table {
  /*background-color: rebeccapurple;*/
  margin-left: 2rem;
  border-collapse: collapse;
  margin-bottom: 2rem;
  margin-top: 1rem;
  width: 50%; }
  .dublin-core-metadata-group .dc-table td {
    padding: 0.25rem 0.5rem;
    border: 2px solid #e8e8e8; }
  .dublin-core-metadata-group .dc-table td:nth-child(1) {
    font-weight: 700; }

@media (max-width: 1100px) {
  .dublin-core-metadata-group .dc-table {
    width: 100%;
    margin-left: 1rem; } }

.drop-files {
  position: relative;
  width: 100%;
  min-height: 20em;
  border: 2px dashed #000;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ededed; }
  .drop-files.drop-files-active {
    background-color: #fff; }
  .drop-files .inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2.5em;
    overflow: hidden; }
    .drop-files .inner .drop-files-icon {
      font-size: 5em; }
    .drop-files .inner .drop-files-label {
      margin: 0;
      margin-top: 0.5em;
      text-align: center; }
    @media (max-width: 300px) {
      .drop-files .inner .drop-files-label {
        display: none; } }

.error-page {
  width: 100%; }
  .error-page .error-page-title {
    color: #ea5d5d;
    margin-top: 0px;
    font-size: 3.5em; }
  .error-page .error-page-text {
    white-space: nowrap;
    overflow-x: auto;
    margin: 0px 1em 0px 1em;
    padding-bottom: 1em;
    font-size: 18px; }

.sort-order .field {
  min-width: 200px;
  margin: 0px 0.5em; }

@media (max-width: 300px) {
  .sort-order .field {
    min-width: 0px; } }

.table-col-filter {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center; }
  .table-col-filter .text-field {
    width: 50% !important;
    min-width: 150px !important; }
    .table-col-filter .text-field.full {
      width: 100% !important; }
      .table-col-filter .text-field.full.width-65 {
        width: 65px !important; }
      .table-col-filter .text-field.full.min-width-65 {
        min-width: 65px !important; }
      .table-col-filter .text-field.full.max-width-65 {
        max-width: 65px !important; }
  .table-col-filter .number-field {
    width: 50% !important;
    min-width: 150px !important; }
    .table-col-filter .number-field.full {
      width: 100% !important; }
  .table-col-filter .select-field {
    width: 50% !important;
    min-width: 150px !important; }
    .table-col-filter .select-field.full {
      width: 100% !important; }
  .table-col-filter .datetimepicker-field {
    width: 50% !important;
    min-width: 150px !important; }
    .table-col-filter .datetimepicker-field.full {
      width: 100% !important; }
  @media screen and (max-width: 600px) {
    .table-col-filter .text-field,
    .table-col-filter .number-field,
    .table-col-filter .select-field,
    .table-col-filter .datetimepicker-field {
      min-width: 100px !important; }
      .table-col-filter .text-field.full,
      .table-col-filter .number-field.full,
      .table-col-filter .select-field.full,
      .table-col-filter .datetimepicker-field.full {
        width: 100% !important; } }

.index-search .form-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 400px;
  padding: 0 0.5em; }
  .index-search .form-row .field {
    max-width: 48%;
    min-width: 100px; }
    .index-search .form-row .field-single {
      max-width: 96%;
      min-width: 100px; }
    .index-search .form-row .field-width-48 {
      width: 48%; }
    .index-search .form-row .field-width-50 {
      width: 50%; }
    .index-search .form-row .field-width-100 {
      width: 100%; }
  .index-search .form-row .full-field {
    max-width: 100%;
    min-width: 100%; }
  .index-search .form-row.sort {
    width: 100%;
    max-width: 100%; }
    .index-search .form-row.sort > div.flex-row {
      flex-wrap: nowrap; }
      .index-search .form-row.sort > div.flex-row .sort {
        min-width: 100px;
        max-width: 300px; }
      .index-search .form-row.sort > div.flex-row .order {
        width: auto;
        max-width: none;
        margin-left: 0.5em; }

.index-search .dublin-core-container {
  display: flex;
  flex-direction: row;
  max-width: 600px; }

.index-search .title {
  margin: 0px; }
  .index-search .title-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    padding: 1em 0.5em;
    margin-bottom: 1em;
    border-top: 1px solid #e8e8e8;
    cursor: pointer; }
    .index-search .title-container.margin-bottom-none {
      margin-bottom: 0px; }
    .index-search .title-container:hover {
      background-color: #f7f7f7; }
  .index-search .title-icon {
    font-size: 24px;
    margin-left: 1em;
    cursor: pointer; }

.index-search .subtitle {
  margin-top: 1em; }
  .index-search .subtitle.margin-top-none {
    margin-top: 0px; }
  .index-search .subtitle-container {
    padding: 0 0.5em; }

.index-search .title,
.index-search .subtitle {
  color: rgba(0, 0, 0, 0.65); }

.index-search-form-buttons {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4em;
  padding: 0 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background-color: #fff;
  border-top: 1px solid #e8e8e8; }

.index-search-form-button.textfield-with-button {
  width: 350px; }

@media (min-width: 600px) and (max-width: 1100px) {
  .index-search .form-row {
    width: 50%;
    max-width: 100%; }
    .index-search .form-row .flex-row {
      flex-wrap: nowrap; }
  .index-search-form-button.textfield-with-button {
    width: 275px; } }

@media (max-width: 700px) {
  .index-search-form-button {
    margin: 0;
    width: 100%; }
    .index-search-form-button.not-primary {
      width: calc(50% - 0.25em); }
      .index-search-form-button.not-primary:first-child {
        margin-right: 0.5em; }
    .index-search-form-button.textfield-with-button {
      width: 100%; }
  .index-search-form-buttons {
    flex-wrap: wrap;
    height: 10em; }
    .index-search-form-buttons-right {
      margin: 0;
      width: 100%; } }

@media (max-width: 600px) {
  .index-search {
    padding-bottom: 8em; }
    .index-search .form-row {
      max-width: 100%; }
      .index-search .form-row .field {
        max-width: 50%; }
        .index-search .form-row .field-width-48 {
          width: 50%; } }

@media (max-width: 400px) {
  .index-search .form-row.sort > div.flex-row {
    flex-wrap: wrap; }
    .index-search .form-row.sort > div.flex-row .order {
      margin-top: 0.5em;
      margin-left: 0; } }

.loader {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.9);
  z-index: 1000000; }
  .loader > div {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }

.no-role {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-role .no-role-text {
    color: #ea5d5d;
    margin: 0;
    text-align: center; }
    @media (max-width: 300px) {
      .no-role .no-role-text {
        font-size: 15px; } }

/* LAYOUT */
.layout {
  min-height: 100vh; }
  .layout.test {
    min-height: calc(100vh - 1.8em); }

/* CONTAINER */
.container {
  width: 100%;
  padding: 0.5em;
  max-height: calc(100vh - 64px);
  overflow: auto; }
  .container.test {
    max-height: calc(100vh - 64px - 1.8em); }
  .container.form {
    max-width: 500px;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center; }

@media (max-width: 800px) {
  .container {
    max-height: none;
    overflow: visible; } }

/* TEST ENVIRONMENT */
.test-stripe {
  height: 1.8em;
  min-height: 1.8em;
  max-height: 1.8em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
  background-color: #037f51;
  color: #fff; }

.pagination {
  margin: 0px; }
  .pagination .form-control {
    width: 100px; }

.sider-menu {
  background-color: #fff; }
  .sider-menu .logo {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    background-color: #001529; }
    .sider-menu .logo.icon {
      justify-content: center; }
      .sider-menu .logo.icon .icon {
        color: #fff; }
    .sider-menu .logo .title {
      color: #fff;
      margin: 0;
      padding: 0;
      padding-left: 24px; }
  .sider-menu.test .menu {
    max-height: calc(100vh - 64px - 1.8em); }
  .sider-menu .menu {
    padding: 0.5em 0;
    max-height: calc(100vh - 64px);
    overflow: hidden; }
    .sider-menu .menu:hover {
      overflow-y: auto; }
    .sider-menu .menu .menu-item,
    .sider-menu .menu .ant-menu-inline {
      margin: 0;
      height: 50px;
      line-height: 50px;
      color: #555; }
    .sider-menu .menu .menu-item:hover,
    .sider-menu .menu .ant-menu-inline:hover {
      color: #337ab7; }
    .sider-menu .menu .ant-menu-item-group .ant-menu-item-group-title {
      margin: 0;
      height: 50px;
      line-height: 50px;
      padding: 0 16px 0 24px;
      vertical-align: middle; }
    .sider-menu .menu .ant-menu-item-group .ant-menu-item-group-list .menu-item.ant-menu-item {
      padding: 0 16px 0 48px !important; }

@media (max-width: 800px) {
  .sider-menu {
    display: none; } }

.table {
  margin: 0; }
  .table-responsive {
    margin: 0;
    margin-bottom: 1em;
    min-height: 40px;
    border: none; }
  .table-with-filter {
    min-height: 350px; }
  .table thead tr th {
    cursor: pointer; }
    .table thead tr th:hover {
      background-color: #ededed; }
    .table thead tr th.no-hover:hover {
      background-color: #fff;
      cursor: auto; }
    .table thead tr th .indicator {
      background-color: #1890ff;
      color: #fff;
      font-size: 1rem;
      font-weight: lighter;
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      border-radius: 50%; }
  .table tbody tr {
    cursor: pointer; }
    .table tbody tr.odd {
      background-color: #f7f7f7; }
    .table tbody tr.even {
      background-color: #fff; }
    .table tbody tr:hover {
      background-color: #ededed; }
    .table tbody tr.no-hover:hover {
      background-color: #fff;
      cursor: auto; }
    .table tbody tr .td-checkbox .checkbox {
      margin: 0;
      margin-top: 1px; }

.table-with-no-margin .table-responsive {
  margin: 0; }

.dragable-column {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  border-radius: 6px;
  background-color: #ededed; }
  .dragable-column label,
  .dragable-column input {
    margin: 0;
    padding: 0;
    cursor: pointer; }

.handle {
  cursor: move; }

.tags-field.card-visible span.ant-input-wrapper.ant-input-group input {
  border-top-left-radius: 0; }

.tags-field.card-visible span.ant-input-wrapper.ant-input-group span.ant-input-group-addon {
  border-top-right-radius: 0; }

.tags-field span.ant-input-group-addon {
  padding: 0;
  cursor: pointer; }
  .tags-field span.ant-input-group-addon span {
    padding: 6px 11px; }

.text-row {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-width: 100%;
  overflow-x: auto; }
  .text-row p:first-child {
    font-weight: bold; }
  .text-row p {
    word-break: break-word;
    max-width: calc(50% - 0.5em);
    padding: 0 0.25em; }
  .text-row .pre {
    max-width: calc(50% - 0.5em);
    margin-bottom: 1em;
    padding: 0 0.25em; }
  @media screen and (max-width: 300px) {
    .text-row {
      flex-direction: column; }
    .text-row p, .text-row .pre {
      max-width: 100%; } }

.ant-tooltip-inner {
  max-width: 80vw;
  word-break: normal;
  text-align: center; }

.ant-tooltip.width-300 > .ant-tooltip-content > .ant-tooltip-inner {
  width: 300px; }

.ant-tooltip.break-all > .ant-tooltip-content > .ant-tooltip-inner {
  word-break: break-all; }

.syntax-highlighter {
  width: 100%;
  border: 1px solid #ccc; }
  .syntax-highlighter.disabled .CodeMirror-scroll {
    background-color: #f7f7f7; }
  .syntax-highlighter-container {
    display: flex;
    flex-direction: column; }
    .syntax-highlighter-container .handle {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f7f7f7;
      height: 20px;
      -webkit-user-select: none;
              user-select: none;
      cursor: row-resize;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc; }
      .syntax-highlighter-container .handle .icon {
        width: 2.2em;
        height: 0.45em;
        border-top: 2px solid #999;
        border-bottom: 2px solid #999; }
